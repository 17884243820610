<template>
  <div class="product">
    <!-- <div class="pic">
      <img src="../../assets/image/product/main.jpg" alt="">
    </div> -->
    <div class="bg">
      <div class="info">
        <h3>用心为客户提供支持与服务</h3>
        <p>您的成功高于一切</p>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
/* .product */
*{
  box-sizing: border-box;
}
.product{
  .bg{
    width: 100%;
    height: 70vh;
    background: url('../../assets/image/product/bg.png') center center no-repeat;
    background-size: cover;
    position: relative;
    color: #fff;
    .info{
      position: absolute;
      top: 50%;
      left: 80px;
      transform: translateY(-50%);
      h3{
        font-size: 50px;
      }
      p{
        font-size: 20px;
        line-height: 50px;
      }
    }
  }
}
</style>
